<template>
    <el-container class="honor">
     <el-header class="honor-top">
       <Header />
     </el-header>
     <el-main class="honor-center">
      <contactUs />
     </el-main>
     <div class="honor-footer">
       <Footer />
      </div>
   </el-container>
 </template>
 
 <script>
 import contactUs from './contactCenter/contactUs.vue'
 import Header from './components/Header.vue'
 import Footer from './components/Footer.vue'
 export default {
 components:{
   Header,Footer,contactUs
 }
 }
 </script>
 
 <style scoped lang="less">
 // .honor{
 //   &-top{
 //     position: fixed;
 //     top:0;
 //   }
 // }
 .honor{
   width: 100%;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   &-top{
     z-index: 999;
     position: fixed;
     top: 0px;
     width: 100%;
     background-color:#fff;
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }
   &-center{
     width: 100%;
     padding-top: 0px;
     margin: 0;
     padding: 0;
     margin-left: 0rem;
     box-sizing: border-box;
   }
   .honor-footer{
      top: 128rem;
      position: absolute;
      width: 100%;
      margin: 0;
      padding: 0;
      background-color:#f4f4f4;
      box-sizing: border-box;
   }
 }
 </style>